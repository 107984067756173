import restaurant from "./images/richaland.jpg";
import biserica from "./images/curchi.jpg";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/mekey_headerh_mb.jpg";
import imgheader from "./images/mekey_headerh.jpg";
import imgheadermiini from "./images/mikey.webp";
import imgheadermiinimb from "./images/headermb.jpg";
import logo from './images/mikey_sleep.png'



const data = {
    introData: [{
        copilul: "Андрей",
        familia: "семиья Бинзарь",
        logo: logo,
        tata: "Aдриан",
        mama: "",
        data: "30 августа 2022 г.",
        data_confirmare: "20 августа 2022",
        savedata: "~ Приглашает вас! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "binzari.adrian@gmail.com", 
       tel: "+373 79 440 808",
       phone: "tel:+37379440808",
       viber: "viber://chat?number=%2B37379440808",
       whatsapp: "https://wa.me/+37379440808",
       messenger: "https://www.messenger.com/t/InvitatiiWeb",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "",
            localul: "Ресторан",
            name: "Richland, зал Рубин",
            data: "30 августа 2022 г, вторнтк, в 17:00 ч.",
            adress: "Гратиешты 2093, Молдова",
            harta: "https://goo.gl/maps/QYhA68RNiLrbHceN8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10866.789736928076!2d28.8271568!3d47.0853967!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x105ba631571eda8b!2sRichland!5e0!3m2!1sru!2s!4v1660151864320!5m2!1sru!2s"
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Ребенок – это подарок от Бога, повод верить в чудеса, это просто счастье на Земле.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "",
            message: "Если вы читаете это сообщение, значит мы ценим вас и будем очень рады если вы присоединитесь к нам в такой особенный день для нас!",
        }
    ],

}

export default data;