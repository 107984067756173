
import React from "react"
import ImgInvitatie from "./ImgInvitatie";
import mikey from '../images/mikey_sleep.png'
import "../css/ImgInvitatie.css"
import data from '../Data';

const CardInvitatie = () => {


    return (

        <section id="invitatie" className="text-center mt-5" >
            <h1>Пригласительная</h1>
            <div className="card card-invitation text-center col-lg-6 col-sm-10  m-auto mb-4">
                <div className="card p-0 overflow-hidden h-100 shadow">


                    <div class="card-body">
                        <div className="text">




                            <h5>Меня зовут,</h5>
                            <h4>Андрей</h4>
                            <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite">
                                <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg>
                                <br />
                            </h5>
                            <h5>
                                Я думаю, вы знаете, что я родился<br />
                                Я даже видел некоторых из вас,<br />
                                Но я предлагаю кое-что<br />
                                Вечеринку в мою честь.<br />
                                Вместе с родителями<br />
                                приглашаем и ждём вас <br />
                                в хорошем настроении<br />
                                на нашем празднике.

                            </h5>


                            <img id="mikey_img" src={mikey} />

                            <h4 class="m-2">Вторник, 30 августа 2022 г.</h4>
                        </div>



                        <ImgInvitatie />




                        <h4 class="card-footer"></h4>

                        <a href="#forms" className="btn btn-primary">Подтвердить Участие</a>

                    </div>




                </div>




            </div>


        </section>

    )


}

export default CardInvitatie;